export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

export const QUERY_KEY_GET_LOCALES = 'QUERY_KEY_GET_LOCALES';
export const QUERY_KEY_GET_FEATURES = 'QUERY_KEY_GET_FEATURES';
export const QUERY_KEY_CHECK_AUTH = 'QUERY_KEY_CHECK_AUTH';
export const QUERY_KEY_GET_LOCALE_BY_ID = 'QUERY_KEY_GET_LOCALE_BY_ID';

export const WIDGET_NAME_DEV = 'telegram-login-GramJs23Bot';
export const WIDGET_NAME_PROD = 'telegram-login-TGAdsNexusBot';

export const BOT_NAME_DEV = 'GramJs23Bot';
export const BOT_NAME_PROD = 'TGAdsNexusBot';

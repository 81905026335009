import { Button, Divider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useQueryClient } from '@tanstack/react-query';

import { FeatureEdit } from './feature-edit';

import { LocaleType } from '../../../types/locale';
import { useCreateFeature, useGetFeatures } from '../../../service/features/hooks';
import { useUpdateLocale } from '../../../service/locales/hooks';
import { QUERY_KEY_GET_FEATURES, QUERY_KEY_GET_LOCALE_BY_ID } from '../../../features/constants';
import { Loader } from '../../common/loader';

export const LocaleEdit = ({ locale }: { locale: LocaleType }) => {
  const queryClient = useQueryClient();

  const [localeName, setLocaleName] = useState(locale.name);
  const [functionName, setFunctionName] = useState('');

  const [debounceValue] = useDebounce(localeName, 500);

  const { mutateAsync: updateLocale } = useUpdateLocale();
  const { data: features, isPending: isPendingGetFeatures } = useGetFeatures();

  const { mutateAsync: createFeature, isPending: isPendingCreate } = useCreateFeature();

  const changeLocaleNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocaleName(e.target.value);
  };

  const changeFunctionNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionName(e.target.value);
  };

  const handleClickCreateFeature = async () => {
    try {
      await createFeature({ name: functionName });

      setFunctionName('');

      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_LOCALE_BY_ID],
      });
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_FEATURES],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debounceValue !== undefined || debounceValue !== null) {
      if (debounceValue !== locale.name) {
        updateLocale({ id: locale.id, name: debounceValue });
      }
    }
  }, [debounceValue]);

  return (
    <div className="p-3">
      <div className="grid gap-2">
        <div>
          <Divider>Настройки локали</Divider>
          <div>
            <label htmlFor="name">Название локали</label>
            <Input id="name" name="name" value={localeName} onChange={changeLocaleNameHandler} />
          </div>
          <Divider>Создать новую фичу</Divider>
          <div className="grid grid-cols-5 items-end gap-1">
            <div className="col-span-4">
              <label htmlFor="function">Название фичи</label>
              <Input id="function" name="function" value={functionName} onChange={changeFunctionNameHandler} />
            </div>

            <Button
              className="col-span-1"
              onClick={handleClickCreateFeature}
              disabled={isPendingCreate || !functionName}
            >
              Создать
            </Button>
          </div>
        </div>
        <div>
          <Divider>Функции</Divider>
          <div className="p-4">
            {isPendingGetFeatures && <Loader />}
            {features?.length === 0 && <div className="text-center">Функций нет</div>}
            {features?.map((feature) => (
              <>
                <FeatureEdit key={feature.id} feature={feature} locale={locale} features={features} />
                <Divider className="my-1.5" />
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import { CreateFeatureType, UpdateFeatureType } from './types';

import { request } from '../axios';
import { FeaturesType } from '../../types/features';

export const featuresService = {
  updateFeature: ({ id, ...rest }: UpdateFeatureType) => {
    return request.patch(`/features/${id}`, { ...rest }).then((res) => res.data);
  },
  removeFeature: (id: string) => {
    return request.delete(`/features/${id}`).then((res) => res.data);
  },
  createFeature: ({ name }: CreateFeatureType) => {
    return request.post(`/features`, { name }).then((res) => res.data);
  },
  getFeatures: () => {
    return request.get<FeaturesType[]>('/features').then((res) => res.data);
  },
};

import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ACCESS_TOKEN_KEY } from '../features/constants';

const url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_SERVER_URL_LOCAL
    : process.env.REACT_APP_SERVER_URL_PROD;

export const request = axios.create({
  baseURL: url,
});

request.interceptors.request.use((config) => {
  const cookies = new Cookies();
  config.headers.Authorization = `Bearer ${cookies.get(ACCESS_TOKEN_KEY) || ''}`;
  return config;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const { response } = error;

    if (error.response?.status === 401 || error.response?.status === 403) {
      const cookies = new Cookies();
      cookies.remove(ACCESS_TOKEN_KEY);
    }

    return response;
  },
);

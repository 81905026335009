import { LoginButton, TelegramAuthData } from '@telegram-auth/react';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { Alert } from 'antd';

import { Loader } from '../common/loader';

import {
  ACCESS_TOKEN_KEY,
  WIDGET_NAME_PROD,
  BOT_NAME_DEV,
  BOT_NAME_PROD,
  WIDGET_NAME_DEV,
} from '../../features/constants';
import { useChechAuth, useGetToken } from '../../service/auth/hooks';
import { AuthContext } from '../../features/context/auth';
import { Header } from '../common/header';

const botName = process.env.NODE_ENV === 'development' ? BOT_NAME_DEV : BOT_NAME_PROD;

const widgetName = process.env.NODE_ENV === 'development' ? WIDGET_NAME_DEV : WIDGET_NAME_PROD;

export default function AuthGuard({ children }: { children: React.ReactNode }) {
  const { mutateAsync: getTokenRequest } = useGetToken();
  const { isPending, data, refetch } = useChechAuth();
  const [user, setUser] = useState<null | any>(null);

  const cookies = new Cookies();

  const getTokenCallback = async (data: TelegramAuthData) => {
    try {
      if (!data) {
        return alert('Данные о пользователе telegram не получены');
      }

      const res = await getTokenRequest({
        auth_date: data.auth_date,
        first_name: data.first_name,
        hash: data.hash,
        id: data.id,
        last_name: data.last_name,
        photo_url: data.photo_url,
        username: data.username,
      });

      if (res.accessToken) {
        cookies.set(ACCESS_TOKEN_KEY, res.accessToken, {
          expires: new Date(Date.now() + 60 * 60 * 1000 * 24 * 30),
        });

        document.getElementById(widgetName)?.remove();
        await refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && data.user) {
      setUser(data?.user);
    }
  }, [data]);

  if (isPending) return <Loader />;

  return (
    <>
      {data?.user ? (
        <>
          <Header />
          <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
        </>
      ) : (
        <div>
          <LoginButton
            botUsername={botName}
            authCallbackUrl={undefined}
            buttonSize="large"
            cornerRadius={5}
            showAvatar={true}
            lang="ru"
            onAuthCallback={getTokenCallback}
          />
          <div className="mt-2">
            <Alert message="Удалить сиссию можно только через telegram. Удалите сессию и перезагрузите страницу." />
          </div>
        </div>
      )}
    </>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { DeleteFilled } from '@ant-design/icons';
import { useContext } from 'react';
import { Button, Switch } from 'antd';

import { FeaturesType } from '../../../types/features';
import { useUpdateFeature } from '../../../service/features/hooks/useUpdateFeature';
import { AuthContext } from '../../../features/context/auth';
import { QUERY_KEY_GET_FEATURES, QUERY_KEY_GET_LOCALE_BY_ID } from '../../../features/constants';
import { useRemoveFeature } from '../../../service/features/hooks';
import { LocaleType } from '../../../types/locale';

export const FeatureEdit = ({
  feature,
  locale,
  features,
}: {
  feature: FeaturesType;
  locale: LocaleType;
  features: FeaturesType[];
}) => {
  const { localeFeatures } = locale;

  const currentFeature = localeFeatures.find((f) => f.featureId === feature.id);

  const { user } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const { mutateAsync: updateFeature, isPending: isPendingUpdate } = useUpdateFeature();

  const { mutateAsync, isPending } = useRemoveFeature();

  const onChange = async () => {
    try {
      await updateFeature({
        id: feature.id,
        isEnabled: !currentFeature?.isEnabled,
        whenChanged: new Date().toISOString(),
        whoChanged: user?.fullName,
        localeId: locale.id,
      });

      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_LOCALE_BY_ID],
      });

      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_FEATURES],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRemoveFeature = async () => {
    try {
      await mutateAsync(feature.id);

      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_LOCALE_BY_ID],
      });
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY_GET_FEATURES],
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-between gap-1">
      <div>
        {feature.name} ID: [{feature.id}]
      </div>
      <div className="flex items-center gap-3">
        <Switch
          disabled={isPendingUpdate}
          checked={currentFeature?.isEnabled}
          checkedChildren="on"
          unCheckedChildren="off"
          onChange={onChange}
        />
        <Button danger onClick={handleClickRemoveFeature} disabled={isPending}>
          <DeleteFilled />
        </Button>
      </div>
    </div>
  );
};

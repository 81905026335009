import { useQuery } from '@tanstack/react-query';

import { featuresService } from '..';
import { QUERY_KEY_GET_FEATURES } from '../../../features/constants';

export const useGetFeatures = () => {
  return useQuery({
    queryKey: [QUERY_KEY_GET_FEATURES],
    queryFn: featuresService.getFeatures,
  });
};

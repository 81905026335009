import { Divider } from 'antd';

import { LocaleList } from './locale-list';
import { AddLocale } from './AddLocale';

export const Locales = () => {
  return (
    <div className="w-full h-full p-3">
      <Divider>Локали</Divider>
      <AddLocale />
      <LocaleList />
    </div>
  );
};

import { Button, Input } from 'antd';
import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useCreateLocale } from '../../../service/locales/hooks';
import { QUERY_KEY_GET_LOCALES } from '../../../features/constants';

export const AddLocale = () => {
  const query = useQueryClient();

  const [localeName, setLocaleName] = useState('');

  const { mutateAsync: createLocale, isPending: isPendingCreateLocale } = useCreateLocale();

  const handleChangeLocale = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocaleName(e.target.value);
  };

  const handleCreateLocale = async () => {
    try {
      await createLocale({ name: localeName });
      setLocaleName('');

      query.refetchQueries({ queryKey: [QUERY_KEY_GET_LOCALES] });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="my-3 flex items-center gap-2">
      <Input placeholder="Название" onChange={handleChangeLocale} value={localeName} disabled={isPendingCreateLocale} />
      <Button onClick={handleCreateLocale} disabled={isPendingCreateLocale || !localeName}>
        Создать локаль
      </Button>
    </div>
  );
};

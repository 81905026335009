import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";

import { router } from "./features/router";
import { Container } from "./components/layout/container";

const client = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={client}>
      <Container>
        <RouterProvider router={router} />
      </Container>
    </QueryClientProvider>
  );
};
